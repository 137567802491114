<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @position-list="$refs.ps._show()">
								<template #mySlot>
									<a-button type="primary" @click="$refs.form._show()">添加广告</a-button>
								</template>
							</common-page-btn>
							<a-button-group></a-button-group>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList()" @change="changekeword" @on-clear="getList()" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6">
									<a-select
										v-model="param.status"
										style="width:100%"
										@change="
											e => {
												this.getList();
											}
										"
										placeholder="根据状态类型进行筛选"
									>
										<a-select-option value="">所有状态</a-select-option>
										<a-select-option value="0">可用</a-select-option>
										<a-select-option value="1">不可用</a-select-option>
									</a-select>
								</a-col>

								<a-col span="6">
									<a-select style="width:100%" v-model="param.positionId" @focus="getposition()" @change="getList()">
										<a-select-option value="">所有位置</a-select-option>
										<a-select-option :value="item.id" v-for="item in positionList" :key="item.id">{{ item.name }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<!-- 数据列表 -->
		<a-table row-key="id" :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25" :style="{ background: '#FFF' }" size="small" border :loading="loading" :data-source="data.records">
			<!-- 图片-->
			<template slot-scope="row" slot="src">
				<img :src="row.src" style="max-height: 30px; max-width: 100%;" />
			</template>
			<!--状态-->
			<template slot-scope="row" slot="status">
				<a-icon v-if="row.status == 1" style="color: #090" type="check-circle" />
				<a-icon type="stop" v-else></a-icon>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改">
						<a-button shape="circle" icon="edit" type="link" @click="$refs.form._show(row)"></a-button>
					</a-tooltip>
					<a-tooltip title="删除">
						<a-button shape="circle" type="link" icon="delete" @click="del(row)"></a-button>
					</a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<!-- 分页 -->
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>

		<banner-form ref="form" @success="getList"></banner-form>
		<position ref="ps"></position>
	</div>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import bannerForm from './components/bannerForm.vue';
import position from './components/position.vue';
export default {
	components: { bannerForm, position },
	data() {
		return {
			data: {},
			loading: false,
			size: '',
			paramForm: '',
			dateMode: 'date',
			dateRange: [],
			positionList: [],
			// 查询参数
			param: {
				page: 1,
				limit: 20,
				keyword: '',
				date: null,
				startDate: null,
				endDate: null,
				status: '',
				positionId: ''
			},
			columns: [
				{ title: '编号', dataIndex: 'id', width: 80, align: 'center' },
				{ title: '图片', scopedSlots: { customRender: 'src' }, width: 150, align: 'center', resizable: true },
				{ title: '标题', dataIndex: 'title', width: 300, align: 'left', resizable: true },
				{ title: '位置', dataIndex: 'positionName', width: 100, align: 'center', resizable: true },
				{ title: '链接', dataIndex: 'link', width: 320, align: 'center', resizable: true },
				{ title: '状态', scopedSlots: { customRender: 'status' }, align: 'center', width: 80 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 160 }
			]
		};
	},

	mounted() {
		this.getList();
	},
	methods: {
		// 获取数据
		getList() {
			this.loading = true;
			request
				.get('/platform/advertising/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		// 删除
		del(data) {
			utils.confirm('您确认要删除吗?').then(() => {
				utils.loading();
				request.delete('/platform/advertising/del/' + data.id, null).then(res => {
					utils.success('删除成功');
					this.getList();
				});
			});
		},
		// 获取广告位置的数据
		getposition() {
			this.http
				.get('/platform/advertising/position', null)
				.then(ret => {
					if (ret.code == 200) {
						this.positionList = ret.data;
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		// 添加表单
		add(data) {
			this.$refs.form.show(data);
		},

		// 当关键字为空时候重新发请求
		changekeword() {
			if (this.param.keyword == '') {
				this.getList();
			}
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	},
	watch: {
		dateRange(v, o) {
			if (v) {
				this.paramModel.startDate = v[0];
				this.paramModel.endDate = v[1];
			} else {
				this.paramModel.startDate = '';
				this.paramModel.endDate = '';
			}
		}
	}
};
</script>

<style></style>
