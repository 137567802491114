<template>
	<a-modal v-model="show" title="广告位置信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<a-button type="primary" v-has="'advertising:position:del'" @click="$refs.form._show()">添加广告位置</a-button>
							<a-button-group></a-button-group>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<!-- 数据列表 -->
		<a-table row-key="id" :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25" :style="{ background: '#FFF' }" size="small" border :loading="loading" :data-source="data">
			<!-- 图片-->
			<template slot-scope="row" slot="record">
				<img :src="row.src" style="max-height: 30px; max-width: 100%;" />
			</template>
			<!--状态-->
			<template slot-scope="row" slot="status">
				<a-icon v-if="row.status == 1" style="color: #090" type="check-circle" />
				<a-icon type="stop" v-else></a-icon>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改">
						<a-button shape="circle" icon="edit" type="link" v-has="'advertising:position:save'" @click="$refs.form._show(row)"></a-button>
					</a-tooltip>
					<a-tooltip title="删除">
						<a-button shape="circle" type="link" icon="delete" status="danger" v-has="'advertising:position:del'" @click="del(row)"></a-button>
					</a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<position-form ref="form" @success="getList"></position-form>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';

import positionForm from './positionForm.vue';
export default {
	components: { upImg, positionForm },
	data() {
		return {
			show: false,
			loading: false,
			data: [],
			columns: [
				{ title: 'ID', dataIndex: 'id', width: 80, align: 'center' },
				{ title: '名字', dataIndex: 'name', width: 100, align: 'center' },
				{ title: '数量', dataIndex: 'num', width: 100, resizable: true },
				{ title: '图片宽度', dataIndex: 'width', width: 100, align: 'center', resizable: true },
				{ title: '图片高度', dataIndex: 'height', width: 100, align: 'center', resizable: true },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 160 }
			]
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.getList();
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/advertising/position', null)
				.then(ret => {
					if (ret.code == 200) {
						this.data = ret.data;
						this.loading = false;
					}
				})
				.catch(err => {
					console.log(err);
					this.loading = false;
				});
		},
		// 删除广告位
		del(row) {
			utils
				.confirm('您确认要删除吗?')
				.then(() => {
					utils.loading();
					request.delete('/platform/advertising/position/del/' + row.id, null).then(res => {
						utils.success('删除成功');
						this.getList();
					});
				})
				.catch(err => {
					console.log(err);
					utils.error('操作失败');
				});
		},
		_close() {
			this.show = false;
		},
		doSubmit() {
			this.show = false;
		}
	}
};
</script>

<style scoped></style>
